
export enum Color {
    BLUE = 'blue',
    GRAY = 'gray',
    GREEN = 'green',
    ORANGE = 'orange',
    PURPLE = 'purple',
    RED = 'red',
}

export const COLORS = [
    Color.BLUE,
    Color.GRAY,
    Color.GREEN,
    Color.ORANGE,
    Color.PURPLE,
    Color.RED,
];
