import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    IAppReducer,
    ISetAccessKeyAction,
    ISetAudioEnabledAction,
    ISetAudioMutedAction,
} from './App.reducer.types';

export const appReducer = createSlice({
  name: 'app',
  initialState: {
    audioEnabled: true,
    audioMuted: false,
    accessKey: null,
  },
  reducers: {
    setAccessKey: (state: IAppReducer, {payload}: PayloadAction<ISetAccessKeyAction>) => {
        state.accessKey = payload.accessKey;
    },
    setAudioEnabled: (state: IAppReducer, {payload}: PayloadAction<ISetAudioEnabledAction>) => {
        state.audioEnabled = payload.audioEnabled;
    },
    setAudioMuted: (state: IAppReducer, {payload}: PayloadAction<ISetAudioMutedAction>) => {
        state.audioMuted = payload.audioMuted;
    },
  },
})

export const {
    setAccessKey,
    setAudioEnabled,
    setAudioMuted,
} = appReducer.actions;

export default appReducer.reducer;
