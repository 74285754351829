import { SvgPaths } from './Icon.types';

export const svgPaths: SvgPaths = {
    cards: {
        path: 'M21.47,4.35L20.13,3.79V12.82L22.56,6.96C22.97,5.94 22.5,4.77 21.47,4.35M1.97,8.05L6.' +
              '93,20C7.24,20.77 7.97,21.24 8.74,21.26C9,21.26 9.27,21.21 9.53,21.1L16.9,18.05C17.65' +
              ',17.74 18.11,17 18.13,16.26C18.14,16 18.09,15.71 18,15.45L13,3.5C12.71,2.73 11.97,2.' +
              '26 11.19,2.25C10.93,2.25 10.67,2.31 10.42,2.4L3.06,5.45C2.04,5.87 1.55,7.04 1.97,8.0' +
              '5M18.12,4.25A2,2 0 0,0 16.12,2.25H14.67L18.12,10.59',
        viewBox: '0 0 24 24',
        transform: '',
        g: {
            transform: '',
        },
    },
    helmet: {
        path: 'M99.5176,38.5844C94.8858,22.6894,68.9488,5.0827, 51.8421.4693l.0017-.0074c-.3465-.1-' +
              '.6891-.2257-1.0357-.325l0,.0051a3.9053, 3.9053,0,0,0-2.4323.1406h-.0169C31.2688,4.60' +
              '45,4.6934,22.4782,0,38.5844,8.5059, 65.8156,8.65,78.38.06,105.6508a3.4361,3.4361,0,0' +
              ',0,.9194,2.91c13.742,10.7343,31.1724, 18.4308,48.78,18.0857,17.6072.3451,35.0379-7.3' +
              '514,48.78-18.0857a3.4358,3.4358,0,0,0, .9194-2.91C90.8675,78.38,91.0115,65.8156,99.5' +
              '176, 38.5844ZM79.6009,69.9912c-3.56-.8793-21.636-9.2872-21.5984-3.4252a170.5538,170.' +
              '5538, 0,0,0,.3437,53.966c1.6521,5.6718-4.7977,3.4711-8.5874, 3.6308-3.79-.16-10.24,2' +
              '.041-8.5876-3.6308a170.542,170.542, 0,0,0,.3437-53.966c.0376-5.862-18.0383,2.5459-21' +
              '.5984,3.4252-5.5815, 1.0429-2.0217-10.284-3.0871-13.4224-.9946-5.8743,25.7534-5.7316' +
              ', 32.9294-6.24,7.176.5082,33.9241.3655,32.93, 6.24C81.6228,59.7072,85.1824,71.0341,7' +
              '9.6009,69.9912Z',
        viewBox: '0 0 99.5176 126.6535',
        transform: '',
        g: {
            transform: '',
        },
    },
    wreath: {
        paths: [
            'M1435 4623 c-46 -12 -143 -58 -195 -93 -122 -83 -231 -240 -265 -381 -21 -90 -20 -215 3 -259 9 -19 21 -35 26 -35 6 0 51 103 102 229 50 127 95 236 100 243 7 11 22 8 75 -13 l66 -26 -103 -259 c-57 -142 -104 -261 -104 -264 0 -3 25 -5 55 -5 103 0 167 51 239 193 59 117 87 237 87 376 0 131 -33 304 -58 300 -5 -1 -17 -4 -28 -6z',
            'M3639 4603 c-40 -103 -52 -332 -24 -453 41 -177 127 -325 217 -371 34 -17 148 -28 148 -14 0 3 -47 122 -104 264 l-103 259 66 26 c53 21 68 24 75 13 5 -7 50 -116 100 -243 51 -126 96 -229 102 -229 5 0 17 16 26 35 23 44 24 169 3 259 -45 189 -204 374 -384 446 -97 39 -110 40 -122 8z',
            'M555 3868 c-59 -148 -66 -176 -72 -300 -7 -144 5 -214 56 -324 65 -138 143 -203 243 -204 l46 0 -88 266 c-61 181 -85 266 -77 269 7 3 38 14 70 24 l58 20 8 -22 c5 -12 44 -129 87 -259 76 -231 79 -237 98 -220 38 34 56 92 56 182 0 236 -109 425 -340 589 -54 39 -101 71 -103 71 -3 0 -22 -42 -42 -92z',
            'M4418 3889 c-230 -166 -338 -354 -338 -589 0 -90 18 -148 56 -182 19 -17 22 -11 98 220 43 130 82 247 87 259 l8 22 58 -20 c32 -10 63 -21 70 -24 8 -3 -16 -88 -77 -269 l-88 -266 46 0 c62 1 119 28 166 80 45 50 109 182 125 258 16 77 14 247 -4 317 -18 71 -94 265 -104 264 -3 0 -50 -32 -103 -70z',
            'M243 2953 c6 -162 26 -243 92 -378 90 -183 173 -254 295 -255 36 0 71 4 78 8 9 6 -28 65 -133 212 -80 112 -145 206 -145 210 0 8 101 80 112 80 4 0 71 -89 148 -197 78 -109 145 -199 149 -201 4 -1 15 14 24 33 22 46 22 146 0 211 -31 92 -227 257 -399 335 -69 32 -199 79 -217 79 -6 0 -8 -53 -4 -137z',
            'M4790 3066 c-163 -58 -309 -143 -428 -251 -90 -82 -115 -129 -120 -229 -4 -82 15 -162 37 -154 6 1 75 94 153 204 l144 202 55 -39 c29 -22 55 -43 57 -47 1 -5 -63 -100 -143 -212 -105 -147 -142 -206 -133 -212 7 -4 41 -8 76 -8 123 0 206 71 297 255 66 134 86 216 92 378 6 156 13 148 -87 113z',
            'M612 2159 c-51 -4 -134 -17 -183 -29 -83 -21 -89 -24 -83 -44 13 -41 76 -162 111 -208 86 -117 241 -220 389 -258 101 -26 233 -27 289 -1 39 17 115 91 115 111 0 10 -23 17 -387 126 -172 51 -233 73 -232 84 0 8 8 40 18 70 14 44 21 54 36 52 11 -2 147 -42 303 -89 156 -46 286 -82 290 -79 15 16 -24 93 -71 140 -46 45 -63 55 -142 79 -144 43 -290 58 -453 46z',
            'M4142 2140 c-119 -28 -196 -65 -243 -118 -37 -43 -69 -115 -56 -128 3 -3 138 33 299 81 161 48 297 88 302 89 9 1 44 -95 45 -124 1 -11 -56 -32 -212 -78 -358 -107 -407 -123 -407 -133 0 -19 77 -93 115 -110 56 -26 188 -25 289 1 162 41 320 152 409 288 43 64 102 190 94 198 -2 2 -44 14 -93 25 -133 31 -427 36 -542 9z',
            'M1311 1505 c-106 -24 -191 -55 -287 -104 -84 -44 -183 -109 -183 -121 -1 -14 117 -88 214 -135 162 -78 249 -99 416 -100 131 0 137 1 177 28 39 25 102 99 102 119 0 4 -124 8 -275 8 l-275 0 0 80 0 80 275 0 c151 0 275 4 275 8 0 20 -63 94 -102 119 -39 26 -49 28 -157 30 -72 1 -140 -3 -180 -12z',
            'M3495 1502 c-48 -23 -125 -106 -125 -134 0 -5 124 -8 275 -8 l275 0 0 -80 0 -80 -275 0 c-151 0 -275 -4 -275 -8 0 -20 63 -94 102 -119 41 -27 45 -28 182 -27 168 1 258 23 421 103 90 45 204 118 204 131 0 13 -114 86 -204 131 -146 73 -252 100 -410 105 -113 4 -135 2 -170 -14z',
            'M2105 1257 c-114 -53 -280 -287 -353 -498 -33 -93 -69 -256 -59 -266 10 -10 173 26 266 59 216 75 451 244 501 359 29 69 29 160 -1 222 -13 26 -26 47 -30 47 -3 0 -86 -93 -184 -207 -98 -115 -180 -209 -184 -210 -3 -2 -30 19 -59 45 l-53 47 175 204 c97 112 173 208 170 212 -3 5 -35 9 -72 9 -49 0 -80 -6 -117 -23z',
            'M2826 1271 c-4 -5 73 -101 169 -213 l175 -203 -52 -47 c-29 -26 -56 -47 -59 -45 -4 1 -86 95 -184 210 -98 114 -181 207 -184 207 -4 0 -17 -21 -30 -46 -26 -54 -30 -137 -10 -204 35 -115 283 -299 510 -378 93 -33 256 -69 266 -59 10 10 -26 173 -59 266 -52 150 -139 295 -250 416 -69 76 -130 105 -220 105 -37 0 -69 -4 -72 -9z'
        ],
        viewBox: '0 0 512 512',
        transform: '',
        g: {
            transform: 'translate(0,512) scale(0.1,-0.1)',
        },
    },
};
