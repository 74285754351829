import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IAccessProps } from './Access.types';
import UserApi from '../../api/User.api';
import { setAccessKey as setAccessKeyAction } from '../App/App.reducer';
import './Access.scss';

export function Access(_props: IAccessProps): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [accessKey, setAccessKey] = useState('');
    const [error, setError] = useState('');

    const onSubmit = async () => {
        let response: any;

        response = await UserApi.validateAccessKey(accessKey);

        if (response.ok) {
            dispatch(setAccessKeyAction({accessKey}));
            return navigate('/');
        } else {
            const error = await response.json();
            setError(error.message);
        }
    }

    return (
        <div className="access-form">
            <div className="introduction">
                <p>
                    Ethnos is a fast-paced area control and set collection game where 2-6 players compete to recruit fantasy creatures
                    and control regions on a map.
                </p>
                <p>
                    The game was designed by Paolo Mori and published by
                    <a href="https://www.cmon.com/" target="_blank" className="link-primary" rel="noreferrer">
                        &nbsp;CMON&nbsp;
                    </a>
                    in 2017, with art by John Howe.
                </p>
                <p>
                    <strong>Ethnos Online</strong> is an unofficial fan-made adaptation of the game.
                </p>
                <p>
                    Is is currently closed to public access.
                </p>
            </div>
            <div className="form-control-wrapper">
                <input
                    className="form-control"
                    placeholder="Access Code"
                    name="accessKey"
                    type="password"
                    autoComplete="off"
                    onChange={(event) => setAccessKey(event.target.value)}
                    value={accessKey}
                />
                    {error ?
                        <div className="form-error">
                            {error}
                        </div> : null
                    }
            </div>
            <div className="form-control-wrapper">
                <button
                    className="btn btn-primary btn-3d btn-block"
                    type="submit"
                    onClick={onSubmit}
                >
                    Send
                </button>
                </div>
        </div>
    );
}
